import React from 'react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { useI18next, Link } from 'gatsby-plugin-react-i18next'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import Layout from '../components/layout'
import { getLocaleFromLanguage } from '../utils'

const videosMap = {
  en: {
    lumo: 'GruBqy-nCiw',
    bibleProject: '7_CGP-12AE0',
    story:
      'https://api.arclight.org/videoPlayerUrl?refId=1_529-jf-0-0&parentSessionId=62fd4e9ed90f97.72015019&apiSessionId=63028af1431904.15796395&playerStyle=vanilla&player=bc.vanilla6',
  },
  hi: {
    lumo: 'mS5brYD9t-o',
    bibleProject: 'IG_ww1RFZ7M',
    story:
      'https://api.arclight.org/videoPlayerUrl?refId=1_6464-jf-0-0&apiSessionId=6333f3381e9a92.73939471&player=bc.vanilla6&dtm=0&playerStyle=default',
  },
}

const getLocaleLandingPage = (data, locale) => {
  return data?.allContentfulLandingPage.nodes.filter(
    (page) => page.node_locale === locale
  )
}

export default function Index({ data }) {
  const { t, language } = useI18next()
  const locale = getLocaleFromLanguage(language)
  const videos = videosMap[language]
  const landingPage = data?.allContentfulLandingPage.nodes.filter(
    (page) => page.node_locale === locale
  )[0]

  return (
    <>
      <Layout>
        <StaticImage
          src="../assets/images/amritvani-hero.jpg"
          alt="Amritvani Media"
          className="w-full max-h-[50vh] mb-8 min-h-[30vh]"
        />
        <div className="w-full flex flex-col items-center px-2 md:px-0">
          <section className="prose lg:prose-xl flex flex-col items-center mt-4">
            <h1 className="self-center px-2 text-3xl">{t('heading')}</h1>
            <p className="px-2">{t('welcome')}</p>
            <ul>
              <li>{t('welcomeList1')}</li>
              <li>{t('welcomeList2')}</li>
              <li>{t('welcomeList3')}</li>
              <li>{t('welcomeList4')}</li>
              <li>{t('haveQuestions')}</li>
            </ul>
            <a href="https://m.me/amritvanimedia" className="text-xl">
              {t('contactUs')}
            </a>
          </section>
          <section className="prose lg:prose-xl flex flex-col gap-4 items-center w-full md:w-3/4 mt-4 md:mt-12">
            <section className="flex flex-col items-center mb-8 w-full">
              <h2 className="">{t('teachingsTitle')}</h2>
              <div className="px-2 flex flex-col items-center w-full">
                <LiteYouTubeEmbed
                  id={videos.lumo}
                  title="The teachings of Jesus from the Lumo Project"
                />
              </div>
              <p className="mb-1 lg:mb-0 mt-8 text-lg">
                {t('teachingsLearnMore')}
              </p>
              <a href="https://m.me/amritvanimedia" className="text-xl">
                {t('contactUs')}
              </a>
            </section>
          </section>
          <section className="prose lg:prose-xl flex flex-col gap-16 items-center w-full md:w-3/4 mt-4 md:mt-12">
            <section className="flex flex-col items-center mb-8 w-full">
              <h2 className="">{t('bibleStoryTitle')}</h2>
              <div className="px-2 flex flex-col items-center w-full">
                <LiteYouTubeEmbed
                  id={videos.bibleProject}
                  title="The story of the Bible from the Bible Project"
                />
              </div>
              <p className="mb-1 lg:mb-0 mt-8 text-lg">
                {t('bibleStoryQuestions')}
              </p>
              <a href="https://m.me/amritvanimedia" className="text-xl">
                {t('contactUs')}
              </a>
            </section>
          </section>
          <section className="prose lg:prose-xl flex flex-col gap-16 items-center w-full md:w-3/4 mt-4 md:mt-12">
            <section className="flex flex-col items-center mb-8 w-full">
              <h2 className="">{t('fullStoryTitle')}</h2>
              <div className="px-2 flex flex-col items-center w-full aspect-video">
                <iframe
                  src={videos.story}
                  allowfullscreen
                  webkitallowfullscreen
                  mozallowfullscreen
                  loading="lazy"
                  className="w-full aspect-video"
                  title={t('fullStoryTitle')}
                ></iframe>
              </div>
              <p className="mb-1 lg:mb-0 mt-8 text-lg">
                {t('fullStoryDiscuss')}
              </p>
              <a href="https://m.me/amritvanimedia" className="text-xl">
                {t('contactUs')}
              </a>
            </section>
          </section>
          <section className="prose lg:prose-xl prose-h3:mt-2 flex flex-col items-center w-full md:w-3/4 mt-16 md:mt-28">
            <h2 className="">{t('latestTopic')}</h2>
            <Link
              to={`/topics/${landingPage.slug}`}
              className="no-underline mx-2 shadow hover:shadow-lg px-6 w-full"
            >
              <h3 className="mt-2">{landingPage.pageHeading}</h3>
              <GatsbyImage image={landingPage.heroImage?.gatsbyImageData} />
              <p>{landingPage.description?.description}</p>
              <p className="underline text-violet-800">{t('learnMore')}</p>
            </Link>
          </section>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query IndexPageQuery($language: String!) {
    bibleProject: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { eq: "bible-project" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
    lumo: allFile(
      filter: { sourceInstanceName: { eq: "images" }, name: { eq: "lumo" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
    allContentfulLandingPage(
      sort: { fields: createdAt, order: DESC }
      limit: 2
    ) {
      nodes {
        node_locale
        pageHeading
        slug
        description {
          description
        }
        heroImage {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
